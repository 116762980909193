import {
    Card,
    CardContent,
    Typography,
  } from "@mui/material";
  
  
  const ResetPasswordNextStep: React.FC = () => {
  
    return (
      <>
        <Card style={{ width: 400 }}>
          <CardContent>
            <Typography color="textSecondary" gutterBottom>
              Password Reset
            </Typography>
            Check your email to complete the password reset process.
          </CardContent>
        </Card>
      </>
    );
  };
  
  export default ResetPasswordNextStep;
  