import { Container } from "@mui/material"

import ScrollToTop from '../../../components/general/ScrollToTop'
function Privacy(props: any) {

    
    return (
        <>
        <ScrollToTop />
        <Container maxWidth="lg">

            <h2>
                Privacy Policy
            </h2>
          
        </Container>
        </>
    )

}

export default Privacy