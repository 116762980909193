/* eslint-disable */
import { useSelector as _useSelector } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import { IRootState } from '../interfaces/store';
import reducer from './reducers/index';
import { mainStateObject } from './reducers/main';
import rootSaga from './sagas/rootSaga'
const init: IRootState = {
  main: {...mainStateObject},
};

export function useSelector<T>(fn: (store: IRootState) => T): T {
  return fn(_useSelector((x: IRootState) => x));
}

export default function store(initialState: IRootState = init) {
  const sagaMiddleware = createSagaMiddleware();
  const middleware = [sagaMiddleware];
  return {
    ...createStore(
      reducer,
      initialState,
      composeWithDevTools(applyMiddleware(...middleware))
    ),
    runSaga: sagaMiddleware.run(rootSaga)
  };
}
